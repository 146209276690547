import React from "react"
import { useIntl } from "react-intl"
import { Search } from "../components/Search/Search"
import { Seo } from "../components/Seo/Seo"
import { buildFaq } from "../utils/buildFaq"

const SearchTemplate = props => {
  const intl = useIntl()

  return (
    <>
      <Seo
        title={intl.formatMessage({ id: "search/results/title" })}
        description={intl.formatMessage({ id: "search/results/description" })}
        indexable={props.pageContext.meta?.indexable}
        domain={props.pageContext.configuration.domain}
        image={props.pageContext.meta?.image?.src?.publicURL}
        lang={props.pageContext.language}
        faq={buildFaq(props.pageContext.page.content)}
      />

      <Search
        page={props.pageContext.page}
        articles={props.pageContext.articles}
        categories={props.pageContext.categories}
        lang={props.pageContext.language}
        theme={props.pageContext.theme}
        header={props.pageContext.header}
        aside={props.pageContext.aside}
        footer={props.pageContext.footer}
        drawer={props.pageContext.drawer}
        language={props.pageContext.language}
        languages={props.pageContext.languages}
        cookies={props.pageContext.cookies}
        authors={props.pageContext.authors}
      />
    </>
  )
}

export default SearchTemplate
